import { clearCookies } from '@/lib/clear-cookies';
import { datadogRum } from '@datadog/browser-rum';
import { useRouter } from 'next/navigation';
import { useCallback } from 'react';
import { useGetSocketToken } from './use-web-socket/use-get-socket-token';
import { closeSocket } from './use-web-socket/utils';
import { useWindowKeeper } from './useWindowKeeper';

export function useLogout() {
  const router = useRouter();
  const { resetPreviews } = useWindowKeeper();
  const socketToken = useGetSocketToken();
  const clearUserData = useCallback(
    ({ withReturnUrl = true } = {}) => {
      clearCookies();
      resetPreviews();
      if (socketToken) {
        closeSocket(socketToken);
      }
      datadogRum.clearUser();
      if (withReturnUrl) {
        router.replace(
          `/login/credentials?nextUrl=${window.location.pathname}`
        );
      } else {
        router.replace('/login/credentials');
      }
    },
    [resetPreviews, router, socketToken]
  );

  return { clearUserData };
}
