import { CogIcon } from '@heroicons/react/16/solid';
import { useTheme } from 'next-themes';

import {
  CommandGroup,
  CommandItem,
  CommandShortcut,
} from '@/components/ui/command';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';

const themes = [
  {
    name: 'System',
    value: 'system',
    shortcut: '1',
  },
  {
    name: 'Ljust',
    value: 'light',
    shortcut: '2',
  },
  {
    name: 'Mörkt',
    value: 'dark',
    shortcut: '3',
  },
];

const keys = ['1', '2', '3', '4'];

export function ThemeGroup({
  setOpen,
  setPages,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setPages: Dispatch<SetStateAction<string[]>>;
}) {
  const { setTheme, theme: appTheme } = useTheme();
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (keys.includes(e.key)) {
        e.preventDefault();
        setTheme(themes[parseInt(e.key) - 1].value);
        setOpen(false);
      }
      if (e.key === 'Escape') {
        setPages([]);
      }
    };

    document.addEventListener('keydown', down);
    return () => {
      document.removeEventListener('keydown', down);
    };
  }, [setOpen, setPages, setTheme]);

  const handleSelect = (theme: string) => {
    setTheme(theme);
    setOpen(false);
  };
  return (
    <CommandGroup heading='Inställningar'>
      {themes.map((theme) => (
        <CommandItem
          key={theme.value}
          onSelect={() => handleSelect(theme.value)}
        >
          <CogIcon className='mr-2 size-4' />
          <span>
            {theme.name} {appTheme === theme.value && '(aktiv)'}
          </span>
          <CommandShortcut>{theme.shortcut}</CommandShortcut>
        </CommandItem>
      ))}
    </CommandGroup>
  );
}
