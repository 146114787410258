'use client';

import { useContext } from 'react';
import { WindowKeeperContext } from '@/providers/windowkeeperProvider';

export const useWindowKeeper = () => {
  const res = useContext(WindowKeeperContext);
  if (!res) {
    throw new Error('WindowKeeper must be used within a WindowKeeperProvider');
  }
  return res;
};
