import { settingsMenuData } from '@/app/settings/data';
import type { ISubMenu } from '@/components/sidebar/data';
import { menuData as dashboardMenuData } from '@/components/sidebar/data';

import {
  CommandGroup,
  CommandItem,
  CommandSeparator,
  CommandShortcut,
} from '@/components/ui/command';
import useIsMac from '@/hooks/use-is-mac';
import { getPerms } from '@/lib/perms';
import { ArrowLongRightIcon } from '@heroicons/react/16/solid';
import type { ModelName } from '@pigello/pigello-matrix';
import { useRouter } from 'next/navigation';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

type RouteData = Array<{ title: string; path: string; modelName?: ModelName }>;

type Menu<T> = {
  [key in keyof T]: {
    subMenus: ISubMenu[];
  };
};

export const getMenuItems = <T,>(menu: Menu<T>) => {
  const routes: RouteData = [];

  const loop = (subMenus: ISubMenu[]) => {
    subMenus.forEach((sm) => {
      if (sm.path) {
        routes.push({
          title: sm.title,
          path: sm.path,
          modelName: sm.modelName,
        });
      }
      if (sm.subMenus) {
        loop(sm.subMenus);
      }
    });
  };

  Object.keys(menu).forEach((key) => {
    const current = menu[key as keyof typeof menu];

    loop(current.subMenus as ISubMenu[]);
  });

  return routes;
};

export function RoutesGroup({
  setOpen,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const router = useRouter();
  const [searchAbleRoutes, setSearchAbleRoutes] = useState<RouteData>([]);
  useEffect(() => {
    const fetchRoutes = async () => {
      const dashboardRoutes = getMenuItems(dashboardMenuData);
      const settingsRoutes = getMenuItems(settingsMenuData);
      const routes: RouteData = [...dashboardRoutes, ...settingsRoutes];
      const routesPromises = routes.map(async (r) => {
        const perms = await getPerms(r.modelName);
        return { ...r, canView: perms.canView };
      });
      const routesWithPerms = await Promise.all(routesPromises);
      const filteredRoutes = routesWithPerms
        .filter((r) => r.canView)
        .sort((a, b) => a.title.localeCompare(b.title));
      setSearchAbleRoutes(filteredRoutes);
    };

    fetchRoutes();
  }, []);

  const isMac = useIsMac();
  return (
    <CommandGroup heading='Navigering'>
      <CommandItem
        onSelect={() => {
          router.back();
          setOpen(false);
        }}
      >
        <ArrowLongRightIcon className='mr-2 size-4' />
        <span>Gå tillbaka...</span>
        <CommandShortcut>{isMac ? '⌘ + B' : 'ctrl + B'}</CommandShortcut>
      </CommandItem>
      <CommandItem
        onSelect={() => {
          router.push('/settings/organization/users');
          setOpen(false);
        }}
      >
        <ArrowLongRightIcon className='mr-2 size-4' />
        <span>Gå till inställningar</span>
      </CommandItem>

      <CommandSeparator />
      {searchAbleRoutes.map((route) => (
        <CommandItem
          key={route.path + route.title}
          onSelect={() => {
            router.push(route.path);
            setOpen(false);
          }}
        >
          <ArrowLongRightIcon className='mr-2 size-4' />
          <span>Gå till {route.title.toLowerCase()}</span>
        </CommandItem>
      ))}
    </CommandGroup>
  );
}
