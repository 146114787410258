'use client';

import { CommandGroup, CommandItem } from '@/components/ui/command';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import type { Dispatch, SetStateAction } from 'react';

interface Props {
  setPages: Dispatch<SetStateAction<string[]>>;
  setSearch: Dispatch<SetStateAction<string>>;
}

export function GlobalSearchGroup({ setPages, setSearch }: Props) {
  return (
    <CommandGroup heading='Global sök'>
      <CommandItem
        onSelect={() => {
          setPages((pages) => [...pages, 'searchable-models']);
          setSearch('');
        }}
      >
        <MagnifyingGlassIcon className='mr-2 size-4' />
        <span>Sök bland objekt</span>
      </CommandItem>
    </CommandGroup>
  );
}
