import type { ISubMenu } from '@/components/sidebar/data';
import { menuData as dashboardMenuData } from '@/components/sidebar/data';
import { settingsMenuData } from '@/app/settings/data';

import { CommandGroup, CommandItem } from '@/components/ui/command';
import { getPerms } from '@/lib/perms';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import type { ModelName } from '@pigello/pigello-matrix';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

type RouteData = Array<{ title: string; path: string; modelName?: ModelName }>;

type Menu<T> = {
  [key in keyof T]: {
    subMenus: ISubMenu[];
  };
};

export const getMenuItems = <T,>(menu: Menu<T>) => {
  const routes: RouteData = [];

  const loop = (subMenus: ISubMenu[]) => {
    subMenus.forEach((sm) => {
      if (sm.path) {
        routes.push({
          title: sm.title,
          path: sm.path,
          modelName: sm.detailModalModelName,
        });
      }
      if (sm.subMenus) {
        loop(sm.subMenus);
      }
    });
  };

  Object.keys(menu).forEach((key) => {
    const current = menu[key as keyof typeof menu];

    loop(current.subMenus as ISubMenu[]);
  });

  return routes;
};

export function SearchableModelsGroup({
  setPages,
  setSearch,
  setModelName,
}: {
  setSearch: Dispatch<SetStateAction<string>>;
  setPages: Dispatch<SetStateAction<string[]>>;
  setModelName: Dispatch<SetStateAction<ModelName | undefined>>;
}) {
  const [searchAbleRoutes, setSearchAbleRoutes] = useState<RouteData>([]);

  useEffect(() => {
    const fetchRoutes = async () => {
      const dashboardRoutes = getMenuItems(dashboardMenuData);
      const settingsRoutes = getMenuItems(settingsMenuData);
      const routesPromises = [...dashboardRoutes, ...settingsRoutes].map(
        async (r) => {
          const perms = await getPerms(r.modelName);
          return { ...r, canView: perms.canView };
        }
      );
      const routesWithPerms = await Promise.all(routesPromises);
      const filteredRoutes = routesWithPerms
        .filter((r) => r.canView)
        .filter((r) => r.modelName !== undefined)
        .sort((a, b) => a.title.localeCompare(b.title));
      setSearchAbleRoutes(filteredRoutes);
    };

    fetchRoutes();
  }, []);

  return (
    <CommandGroup heading='Välj modell'>
      {searchAbleRoutes.map((route) => (
        <CommandItem
          key={route.path + route.title}
          onSelect={() => {
            setModelName(route.modelName);
            setPages((pages) => [...pages, 'search']);
            setSearch('');
          }}
        >
          <MagnifyingGlassIcon className='mr-2 size-4' />
          <span>
            Sök på{' '}
            <span className='first-letter:capitalize'>
              {route.title.toLowerCase()}
            </span>
          </span>
        </CommandItem>
      ))}
    </CommandGroup>
  );
}
