import { getCookies } from 'cookies-next';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

export function useGetSocketToken() {
  const cookies = getCookies();
  const userId = cookies?.user_id;
  const token = cookies?.token;
  const customerId = cookies?.customer_id;
  const pathname = usePathname();
  const base64Token = useMemo(() => {
    if (!userId || !token || !customerId || pathname.includes('login')) {
      return null;
    }
    return btoa(`${customerId}.${userId}:${token}`);
  }, [customerId, pathname, token, userId]);

  return base64Token;
}
