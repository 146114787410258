import { Urls } from '@/lib/urls';
import {
  ArrowTrendingUpIcon,
  BookOpenIcon,
  BriefcaseIcon,
  BuildingOffice2Icon,
  CalculatorIcon,
  CubeIcon,
  DocumentChartBarIcon,
  DocumentCheckIcon,
  DocumentDuplicateIcon,
  DocumentMinusIcon,
  EnvelopeIcon,
  HomeModernIcon,
  InboxIcon,
  PaintBrushIcon,
  RectangleStackIcon,
  ScaleIcon,
  UserGroupIcon,
  UsersIcon,
} from '@heroicons/react/16/solid';
import {
  ApartmentContractConfig,
  type ModelName,
} from '@pigello/pigello-matrix';

type GroupType = 'userMenu' | 'objects' | 'modules' | 'processes';

export interface IMenuGroup {
  title: string;
  isIndex?: boolean;
  key: string;
  subMenus: ISubMenu[];
}

export interface ISubMenu {
  title: string;
  path?: string;
  key: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  modelName?: ModelName;
  detailModalModelName?: ModelName;
  modelNames?: ModelName[];
  additionalModelNames?: ModelName[]; // special case for when a modelname is not directly required, but UI-wise we want to hide it under a different modelname
  subMenus?: ISubMenu[];
  hidden?: boolean;
  pigelloUserOnly?: boolean;
}

export type Menu = Record<GroupType, IMenuGroup>;

export const menuData: Menu = {
  userMenu: {
    title: 'Meny',
    isIndex: true,
    key: 'userMenu',
    subMenus: [
      {
        path: Urls.dashboard.home,
        key: 'home',
        title: 'Min arbetsdag',
        icon: <BriefcaseIcon className='mr-2 size-4 min-w-[16px]' />,
      },
      {
        icon: <InboxIcon className='mr-2 size-4 min-w-[16px]' />,
        title: 'Inkorg',
        key: 'monitor-inbox',
        modelName: 'monitoredinstanceaction',
        subMenus: [
          {
            title: 'Godkännanden',
            modelNames: [
              'apartment',
              'article',
              'verificationgroup',
              'capitalcontributionnotation',
              'industrialpremises',
              'parkingspot',
              'outdoorsection',
              'building',
              'realestate',
            ],
            key: 'monitored-proposals',
            path: Urls.dashboard.inbox.approvals,
          },
          {
            title: 'Genomföranden',
            modelNames: [
              'apartment',
              'article',
              'verificationgroup',
              'capitalcontributionnotation',
              'industrialpremises',
              'parkingspot',
              'outdoorsection',
              'building',
              'realestate',
            ],
            key: 'monitored-performs',
            path: Urls.dashboard.inbox.performs,
          },
          {
            title: 'Signeringar',
            key: 'pending-signings',
            path: Urls.dashboard.inbox.signings,
          },
          {
            title: 'Retroaktiva debiteringar',
            key: 'retroactivity',
            path: Urls.dashboard.inbox.retroactivity,
          },
          {
            title: 'Fördelningkvoter',
            key: 'distribution-quotas',
            path: Urls.dashboard.inbox.distribution,
          },
        ],
      },
    ],
  },
  objects: {
    title: 'Objektsdatabas',
    key: 'objects',
    subMenus: [
      {
        path: Urls.dashboard.companies,
        icon: <RectangleStackIcon className='mr-2 size-4 min-w-[16px]' />,
        title: 'Bolag',
        modelName: 'company',
        detailModalModelName: 'company',
        subMenus: [],
        key: 'companies',
      },
      {
        path: Urls.dashboard.brfCompanies,
        icon: <ScaleIcon className='mr-2 size-4 min-w-[16px]' />,
        title: 'Bostadsrättsföreningar',
        modelName: 'brfcompany',
        detailModalModelName: 'brfcompany',
        subMenus: [],
        key: 'brfcompanies',
      },
      {
        path: Urls.dashboard.externalclients,
        icon: <BookOpenIcon className='mr-2 size-4 min-w-[16px]' />,
        title: 'Uppdragsgivare',
        modelName: 'externalclient',
        detailModalModelName: 'externalclient',
        subMenus: [],
        key: 'externalclients',
      },
      {
        title: 'Strukturobjekt',
        key: 'structural',
        icon: <BuildingOffice2Icon className='mr-2 size-4 min-w-[16px]' />,
        modelNames: [
          'region',
          'community',
          'segment',
          'realestate',
          'outdoorarea',
          'building',
          'parkinglot',
          'address',
        ],
        subMenus: [
          {
            key: 'regions',
            path: Urls.dashboard.structural.regions,
            title: 'Regioner',
            modelName: 'region',
            detailModalModelName: 'region',
          },
          {
            key: 'communities',
            path: Urls.dashboard.structural.communities,
            title: 'Områden',
            modelName: 'community',
            detailModalModelName: 'community',
          },
          {
            key: 'segments',
            path: Urls.dashboard.structural.segments,
            title: 'Segment',
            modelName: 'segment',
            detailModalModelName: 'segment',
          },
          {
            key: 'realestates',
            path: Urls.dashboard.structural.realestates,
            title: 'Fastigheter',
            modelName: 'realestate',
            detailModalModelName: 'realestate',
          },
          {
            key: 'outdoorareas',
            path: Urls.dashboard.structural.outdoorareas,
            title: 'Utomhusområden',
            modelName: 'outdoorarea',
            detailModalModelName: 'outdoorarea',
          },
          {
            key: 'buildings',
            path: Urls.dashboard.structural.buildings,
            title: 'Byggnader',
            modelName: 'building',
            detailModalModelName: 'building',
          },
          {
            key: 'parkinglots',
            path: Urls.dashboard.structural.parkinglots,
            title: 'Parkeringsområden',
            modelName: 'parkinglot',
            detailModalModelName: 'parkinglot',
          },
          {
            key: 'addresses',
            path: Urls.dashboard.structural.addresses,
            title: 'Adresser',
            modelName: 'address',
            detailModalModelName: 'address',
          },
        ],
      },
      {
        title: 'Objekt',
        key: 'rentable',
        icon: <CubeIcon className='mr-2 size-4 min-w-[16px]' />,
        modelNames: [
          'apartment',
          'industrialpremises',
          'outdoorsection',
          'parkingspot',
          'commonarea',
          'brfapartment',
        ],
        subMenus: [
          {
            key: 'apartments',
            path: Urls.dashboard.apartments,
            title: 'Lägenheter',
            modelName: 'apartment',
            detailModalModelName: 'apartment',
          },
          {
            key: 'industrialpremises',
            path: Urls.dashboard.industrialPremises,
            title: 'Lokaler',
            modelName: 'industrialpremises',
            detailModalModelName: 'industrialpremises',
          },
          {
            key: 'outdoorsections',
            path: Urls.dashboard.outdoorSections,
            title: 'Utomhussektioner',
            modelName: 'outdoorsection',
            detailModalModelName: 'outdoorsection',
          },
          {
            key: 'parkingspots',
            path: Urls.dashboard.parkingSpots,
            title: 'Fordonsplatser',
            modelName: 'parkingspot',
            detailModalModelName: 'parkingspot',
          },
          {
            key: 'commonareas',
            path: Urls.dashboard.commonAreas,
            title: 'Gemensamma ytor',
            modelName: 'commonarea',
            detailModalModelName: 'commonarea',
          },
          {
            key: 'brf',
            path: Urls.dashboard.brf.brfApartments,
            title: 'Bostadsrätter',
            icon: <HomeModernIcon className='mr-2 size-4 min-w-[16px]' />,
            modelName: 'brfapartment',
            detailModalModelName: 'brfapartment',
          },
        ],
      },
    ],
  },
  modules: {
    key: 'modules',
    title: 'Moduler',
    subMenus: [
      {
        path: Urls.dashboard.tenants,
        key: 'tenants',
        title: 'Hyresgäster',
        icon: <UsersIcon className='mr-2 size-4 min-w-[16px]' />,
        modelName: 'tenant',
        detailModalModelName: 'tenant',
      },
      {
        path: Urls.dashboard.brfMembers,
        key: 'brfmembers',
        title: 'Medlemmar',
        icon: <UserGroupIcon className='mr-2 size-4 min-w-[16px]' />,
        modelName: 'tenant',
        additionalModelNames: ['brfapartment'],
      },
      {
        path: Urls.documents.overview,
        key: 'documents',
        title: 'Dokument',
        icon: <DocumentDuplicateIcon className='mr-2 size-4 min-w-[16px]' />,
        modelNames: ['file', 'folder'],
      },
      {
        title: 'Avtal',
        key: 'contracts',
        icon: <DocumentCheckIcon className='mr-2 size-4 min-w-[16px]' />,
        modelNames: [
          'apartmentcontract',
          'industrialpremisescontract',
          'parkingspotcontract',
          'outdoorsectioncontract',
          'othercontract',
          'blockcontract',
        ],
        subMenus: [
          {
            key: 'apartmentcontracts',
            path: Urls.dashboard.contracts.apartment,
            title: 'Lägenhetsavtal',
            modelName: 'apartmentcontract',
            detailModalModelName: 'apartmentcontract',
          },
          {
            key: 'industrialpremisescontracts',
            path: Urls.dashboard.contracts.industrialPremises,
            title: 'Lokalavtal',
            modelName: 'industrialpremisescontract',
            detailModalModelName: 'industrialpremisescontract',
          },
          {
            key: 'parkingspotcontracts',
            path: Urls.dashboard.contracts.parkingSpot,
            title: 'Parkeringsavtal',
            modelName: 'parkingspotcontract',
            detailModalModelName: 'parkingspotcontract',
          },
          {
            key: 'outdoorsectioncontracts',
            path: Urls.dashboard.contracts.outdoorSection,
            title: 'Utomhussektionsavtal',
            modelName: 'outdoorsectioncontract',
            detailModalModelName: 'outdoorsectioncontract',
          },
          {
            key: 'othercontracts',
            path: Urls.dashboard.contracts.otherContracts,
            title: 'Övriga avtal',
            modelName: 'othercontract',
            detailModalModelName: 'othercontract',
          },
          {
            key: 'blockcontracts',
            path: Urls.dashboard.contracts.blockContracts,
            title: 'Blockavtal',
            modelName: 'blockcontract',
            detailModalModelName: 'blockcontract',
          },
        ],
      },
      {
        key: 'vacancy-notations',
        title: 'Vakanser',
        icon: <DocumentMinusIcon className='mr-2 size-4 min-w-[16px]' />,
        modelNames: [
          'apartmentvacancynotation',
          'industrialpremisesvacancynotation',
          'outdoorsectionvacancynotation',
          'parkingspotvacancynotation',
          'vacancyinvoice',
        ],
        subMenus: [
          {
            key: 'vacancyinvoices',
            path: Urls.dashboard.invoicing.vacancyInvoices,
            title: 'Vakansunderlag',
            detailModalModelName: 'vacancyinvoice',
          },
          {
            key: 'apartment-vacancy-notations',
            path: Urls.dashboard.vacancyContracts.apartment,
            title: 'Lägenhetsvakansavtal',
            modelName: 'apartmentvacancynotation',
            detailModalModelName: 'apartmentvacancynotation',
          },
          {
            key: 'industrial-premises-vacancy-notations',
            path: Urls.dashboard.vacancyContracts.industrialPremises,
            title: 'Lokalvakansavtal',
            modelName: 'industrialpremisesvacancynotation',
            detailModalModelName: 'industrialpremisesvacancynotation',
          },
          {
            key: 'outdoorsection-vacancy-notations',
            path: Urls.dashboard.vacancyContracts.outdoorSection,
            title: 'Utomhussektionsvakansavtal',
            modelName: 'outdoorsectionvacancynotation',
            detailModalModelName: 'outdoorsectionvacancynotation',
          },
          {
            key: 'parkingspot-vacancy-notations',
            path: Urls.dashboard.vacancyContracts.parkingSpot,
            title: 'Parkeringsvakansavtal',
            modelName: 'parkingspotvacancynotation',
            detailModalModelName: 'parkingspotvacancynotation',
          },
        ],
      },
      {
        key: 'digital-signing',
        title: 'Digital signering',
        icon: <DocumentDuplicateIcon className='mr-2 size-4 min-w-[16px]' />,
        modelNames: ['signabledocument'],
        subMenus: [
          {
            title: 'Signeringsprocesser',
            key: 'signing-processes',
            path: Urls.dashboard.digitalSigning.processes(
              ApartmentContractConfig.modelName
            ),
          },
          {
            title: 'Mallar',
            key: 'templates',
            path: Urls.dashboard.digitalSigning.templates,
          },
        ],
      },
      {
        title: 'Hyra & Fakturering',
        key: 'rent',
        icon: <EnvelopeIcon className='mr-2 size-4 min-w-[16px]' />,
        modelNames: ['invoice'],
        subMenus: [
          // {
          //   key: 'invoiceoverview',
          //   path: Urls.dashboard.invoicing.overview,

          //   title: 'Översikt',
          // },
          {
            key: 'faileddeliveries',
            path: Urls.dashboard.invoicing.failedDelivieries,
            title: 'Misslyckade leveranser',
            modelNames: ['notification', 'notificationdistribution'],
          },
          {
            key: 'invoices',
            path: Urls.dashboard.invoicing.invoices,
            title: 'Avier & Fakturor',
            detailModalModelName: 'invoice',
            modelName: 'invoice',
          },

          // {
          //   key: 'accounting',
          //   path: Urls.dashboard.invoicing.accounting,
          //   title: 'Bokföring',
          // },
          // {
          //   key: 'accounts',
          //   path: Urls.dashboard.invoicing.accounts,
          //   title: 'Reskontra',
          // },
          {
            key: 'payments',
            path: Urls.dashboard.invoicing.payments,
            title: 'Inbetalningar',
          },
          {
            key: 'autogiro',
            path: Urls.dashboard.invoicing.autogiroTransactionAttempts,
            title: 'Autogirodragningar',
          },
          {
            key: 'verificationGroups',
            path: Urls.dashboard.invoicing.verificationGroups,
            title: 'Verifikationsgrupper',
            modelNames: ['bookkeepingaccountsconfig'],
          },
          {
            key: 'bookkeepingExport',
            path: Urls.dashboard.invoicing.bookkeepingExport,
            title: 'Bokföringsexport',
            modelNames: ['bookkeepingaccountsconfig'],
          },
        ],
      },
      // {
      //   title: 'Marknad',
      //   key: 'rental',
      //   icon: <MegaphoneIcon className='mr-2 size-4 min-w-[16px]' />,
      //   subMenus: [
      //     {
      //       key: 'queues',
      //       path: Urls.dashboard.market.pipes,
      //       title: 'Köhantering',
      //     },
      //     {
      //       key: 'ads',
      //       path: Urls.dashboard.market.ads,
      //       title: 'Annonser',
      //     },
      //     {
      //       key: 'leads',
      //       path: Urls.dashboard.market.leads,
      //       title: 'Sökanden',
      //     },
      //     {
      //       key: 'marketplace',
      //       path: Urls.dashboard.market.marketplace,
      //       title: 'Marknadsplats',
      //     },
      //   ],
      // },
      // {
      //   title: 'Teknisk förvaltning',
      //   key: 'technical-management',
      //   icon: <WrenchIcon className='mr-2 size-4 min-w-[16px]' />,
      //   subMenus: [
      //     {
      //       key: 'errands',
      //       path: Urls.dashboard.technicalManagement.errands,
      //       title: 'Ärenden',
      //     },
      //     {
      //       key: 'inspections',
      //       path: Urls.dashboard.technicalManagement.inspections,
      //       title: 'Besiktning',
      //     },
      //     {
      //       key: 'rounding',
      //       path: Urls.dashboard.technicalManagement.rounding,
      //       title: 'Rondering',
      //     },
      //     {
      //       key: 'gov',
      //       path: Urls.dashboard.technicalManagement.gov,
      //       title: 'Myndighetskrav',
      //     },
      //   ],
      // },
      // {
      //   title: 'Nyckelhantering',
      //   key: 'keys',
      //   icon: <KeyIcon className='mr-2 size-4 min-w-[16px]' />,
      //   subMenus: [
      //     {
      //       key: 'key',
      //       path: Urls.dashboard.keyhandling.keys,
      //       title: 'Nycklar',
      //     },
      //     {
      //       key: 'keyreceipts',
      //       path: Urls.dashboard.keyhandling.receipts,
      //       title: 'Nyckelkvittenser',
      //     },
      //   ],
      // },
      {
        title: 'Rapporter',
        path: Urls.dashboard.reports.available,
        key: 'reports',
        modelNames: ['reportresult', 'reporttemplate'],
        icon: <DocumentChartBarIcon className='mr-2 size-4 min-w-[16px]' />,
        hidden: process.env.NEXT_PUBLIC_CLIENT_TYPE === 'prod',
        pigelloUserOnly: true,
      },
    ],
  },
  processes: {
    key: 'processes',
    title: 'Processer',
    subMenus: [
      // {
      //   title: 'Utskick',
      //   path: Urls.dashboard.sendouts,
      //   key: 'sendouts',
      //   icon: <EnvelopeIcon className='mr-2 size-4 min-w-[16px]' />,
      // },
      {
        title: 'Debiteringshantering',
        key: 'revenue',
        icon: <CalculatorIcon className='mr-2 size-4 min-w-[16px]' />,
        subMenus: [
          {
            key: 'key1',
            path: Urls.dashboard.revenue.distribution.default,
            title: 'Fördelningsavgifter',
          },
          {
            key: 'key2',
            path: Urls.dashboard.revenue.indexation.default,
            title: 'Indexuppräkning',
            modelNames: ['indexationtable', 'indexationsettingusage'],
          },
          {
            key: 'key3',
            path: Urls.dashboard.revenue.revenueIncrease.overview,
            title: 'Avgiftsändringar',
          },
          {
            key: 'key4',
            path: Urls.dashboard.revenue.taxation.overview,
            title: 'Fastighetsskatt',
            modelNames: ['taxationrecord', 'taxationsubrecord'],
          },
          {
            key: 'key5',
            path: Urls.dashboard.revenue.debitRows.apartment,
            title: 'Massjustera debiteringsrader',
            modelNames: [
              'apartmentrevenuerow',
              'industrialpremisesrevenuerow',
              'outdoorsectionrevenuerow',
              'parkingspotrevenuerow',
              'brfapartmentrevenuerow',
              'apartmentcontractrevenuerow',
              'industrialpremisescontractrevenuerow',
              'outdoorsectioncontractrevenuerow',
              'parkingspotcontractrevenuerow',
              'othercontractrevenuerow',
            ],
          },
          {
            key: 'key6',
            path: Urls.dashboard.revenue.retroactivity.default,
            modelNames: [
              'retroactiveapartmentrevenueproposal',
              'retroactiveindustrialpremisesrevenueproposal',
              'retroactiveparkingspotrevenueproposal',
              'retroactiveoutdoorsectionrevenueproposal',
            ],
            title: 'Retroaktivitet',
          },
        ],
      },
      {
        title: 'IMD',
        key: 'imd',
        icon: <ArrowTrendingUpIcon className='mr-2 size-4 min-w-[16px]' />,
        modelNames: ['sensor', 'sensorspecification', 'pricegroup'],
        subMenus: [
          {
            key: 'sensorspecification',
            path: Urls.dashboard.imd.sensorspecifications,
            title: 'Mätartyper',
          },
          {
            key: 'sensors',
            path: Urls.dashboard.imd.sensors,
            title: 'Sensorer',
          },
          {
            key: 'pricegroups',
            path: Urls.dashboard.imd.pricegroups,
            title: 'Prisgrupper',
          },
          {
            key: 'importimddata',
            path: Urls.dashboard.imd.importdata,
            title: 'Importera mätvärden',
          },
        ],
      },
      {
        title: 'Renoveringar',
        key: 'renovations',
        icon: <PaintBrushIcon className='mr-2 size-4 min-w-[16px]' />,
        path: Urls.dashboard.renovations.overview,
      },
    ],
  },
};
