import { CommandGroup, CommandItem } from '@/components/ui/command';
import { useLogout } from '@/hooks/useLogout';
import {
  ArrowLeftEndOnRectangleIcon,
  UserIcon,
} from '@heroicons/react/16/solid';

import { useRouter } from 'next/navigation';
import type { Dispatch, SetStateAction } from 'react';

export function AccountGroup({
  setOpen,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const router = useRouter();
  const { clearUserData } = useLogout();
  const handleSelect = (path: string) => {
    router.push(path);
    setOpen(false);
  };
  return (
    <CommandGroup heading='Konto'>
      <CommandItem onSelect={() => handleSelect('/settings/profile/settings')}>
        <UserIcon className='mr-2 size-4' />
        <span>Profilinställningar</span>
      </CommandItem>
      <CommandItem onSelect={() => clearUserData({ withReturnUrl: false })}>
        <ArrowLeftEndOnRectangleIcon className='mr-2 size-4' />
        <span>Logga ut</span>
      </CommandItem>
    </CommandGroup>
  );
}
