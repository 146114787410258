'use client';

import { CommandGroup, CommandItem } from '@/components/ui/command';
import { CogIcon } from '@heroicons/react/16/solid';
import type { Dispatch, SetStateAction } from 'react';

interface Props {
  setPages: Dispatch<SetStateAction<string[]>>;
  setSearch: Dispatch<SetStateAction<string>>;
}

export function SettingsGroup({ setPages, setSearch }: Props) {
  return (
    <CommandGroup heading='Inställningar'>
      <CommandItem
        onSelect={() => {
          setPages((prev) => [...prev, 'theme']);
          setSearch('');
        }}
      >
        <CogIcon className='mr-2 size-4' />
        <span>Byt tema</span>
      </CommandItem>
    </CommandGroup>
  );
}
