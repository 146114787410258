import { BASE_PIGELLO_SOCKET_URL } from '@/requests/constants';
import { sockets } from './socket-map';

/**
 * Utility to build a URL with query parameters.
 * @param url - The base URL.
 * @param params - An object containing query parameters.
 * @returns The URL with appended query parameters.
 */
export const buildUrlWithParams = (
  url: string,
  params?: Record<string, string | number | boolean>
): string => {
  if (!params) return url;
  const urlObj = new URL(url, window.location.origin);
  for (const [key, value] of Object.entries(params)) {
    urlObj.searchParams.append(key, String(value));
  }
  return urlObj.toString();
};

/**
 * Closes the WebSocket connection for a given URL and token.
 * @param url - The WebSocket server URL. If not provided, `BASE_SOCKET_URL` is used.
 * @param token - Optional token to append as a query parameter.
 */
export const closeSocket = (token: string, url?: string) => {
  if (!BASE_PIGELLO_SOCKET_URL) {
    return;
  }
  if (!token) {
    throw new Error('No token found');
  }
  const fullUrl = buildUrlWithParams(
    url ?? BASE_PIGELLO_SOCKET_URL,
    token ? { b: token } : undefined
  );
  const manager = sockets.get(fullUrl);
  if (manager) {
    manager.close();
    sockets.delete(fullUrl);
  }
};

/**
 * Retrieves the underlying WebSocket instance for a given URL and token.
 * @param url - The WebSocket server URL. If not provided, `BASE_SOCKET_URL` is used.
 * @param token - Optional token to append as a query parameter.
 * @returns The WebSocket instance or `null` if not found.
 */
export const getSocket = (url?: string, token?: string): WebSocket | null => {
  if (!BASE_PIGELLO_SOCKET_URL) {
    return null;
  }
  const fullUrl = buildUrlWithParams(
    url ?? BASE_PIGELLO_SOCKET_URL,
    token ? { b: token } : undefined
  );
  const manager = sockets.get(fullUrl);
  return manager ? manager.getSocketInstance() : null;
};
