/* eslint-disable jsx-a11y/no-autofocus */
'use client';

import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandInput,
  CommandList,
} from '@/components/ui/command';
import { DropdownMenuShortcut } from '@/components/ui/dropdown-menu';
import useIsMac from '@/hooks/use-is-mac';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import type { ModelName } from '@pigello/pigello-matrix';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import { AccountGroup } from './components/account';
import { GlobalSearchGroup } from './components/global-search-group';
import { RecentSearches } from './components/recent-searches';
import { RoutesGroup } from './components/routes';
import { SearchGroup } from './components/search-group';
import { SearchableModelsGroup } from './components/searchable-models';
import { SettingsGroup } from './components/settings';
import { ThemeGroup } from './components/theme';

export default function GlobalSearch({
  hiddenInput,
}: {
  hiddenInput: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [asyncSearch, setAsyncSearch] = useState('');
  const [modelName, setModelName] = useState<ModelName>();
  const [pages, setPages] = useState<string[]>([]);
  const page = pages[pages.length - 1];
  const debouncedSearch = useDebounceCallback(setAsyncSearch, 300);
  const router = useRouter();
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
      if (e.key === 'Escape' && open) {
        e.preventDefault();
        setOpen(false);
      }

      if (e.key === 'b' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        router.back();
      }
    };

    document.addEventListener('keydown', down);
    return () => {
      setPages([]);
      setSearch('');
      document.removeEventListener('keydown', down);
    };
  }, [open, router]);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'Backspace' && !asyncSearch && !search && open) {
        e.preventDefault();
        setPages((pages) => pages.slice(0, -1));
      }
    };

    document.addEventListener('keydown', down);
    return () => {
      document.removeEventListener('keydown', down);
    };
  }, [asyncSearch, open, page, search]);

  const isMac = useIsMac();
  return (
    <>
      {!hiddenInput && (
        <button
          onClick={() => setOpen(true)}
          className='flex w-full items-center justify-start rounded-sm border border-input bg-muted py-1  pl-3 pr-1 text-sm text-muted-foreground shadow-md'
        >
          <MagnifyingGlassIcon className='mr-2 size-4' />
          Sök i systemet{' '}
          <DropdownMenuShortcut>
            {isMac ? '⌘ + K' : 'ctrl + K'}
          </DropdownMenuShortcut>
        </button>
      )}
      <CommandDialog open={open} onOpenChange={setOpen}>
        <Command
          shouldFilter={page !== 'search'}
          loop={page !== 'search' && !modelName}
        >
          {page !== 'search' && (
            <CommandInput
              autoFocus
              value={search}
              onValueChange={setSearch}
              placeholder='Sök i systemet...'
            />
          )}
          {page === 'search' && (
            <CommandInput
              autoFocus
              value={search}
              onValueChange={(e) => {
                setSearch(e);
                debouncedSearch(e);
              }}
              placeholder='Sök i systemet...'
            />
          )}
          <CommandList>
            {page !== 'search' && (
              <CommandEmpty>Inga resultat hittades.</CommandEmpty>
            )}
            {!page && (
              <>
                {!search && <RecentSearches setOpen={setOpen} />}
                <SettingsGroup setSearch={setSearch} setPages={setPages} />
                <AccountGroup setOpen={setOpen} />
                <SearchableModelsGroup
                  setPages={setPages}
                  setSearch={setSearch}
                  setModelName={setModelName}
                />
                <GlobalSearchGroup setSearch={setSearch} setPages={setPages} />
                <RoutesGroup setOpen={setOpen} />
              </>
            )}
            {page === 'theme' && (
              <ThemeGroup setOpen={setOpen} setPages={setPages} />
            )}
            {page === 'searchable-models' && (
              <SearchableModelsGroup
                setPages={setPages}
                setSearch={setSearch}
                setModelName={setModelName}
              />
            )}
            {page === 'search' && modelName && (
              <SearchGroup
                modelName={modelName}
                search={asyncSearch}
                setOpen={setOpen}
              />
            )}
          </CommandList>
        </Command>
      </CommandDialog>
    </>
  );
}
